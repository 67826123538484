import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <Helmet>
      <script
        src="https://js.churchcenter.com/modal/v1"
        type="text/javascript"
      />
    </Helmet>

    <SEO title="Contact" />
    <div className="container">
      <h1>Contact</h1>
      <p></p>
      <a
        href="https://fiema.churchcenter.com/people/forms/186397"
        data-open-in-church-center-modal="true"
        className="btn gradient-bg mr-2"
      >
        Contact Us
      </a>
    </div>
  </Layout>
)

export default Contact
